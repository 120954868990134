import "vite/modulepreload-polyfill";

import { Application } from "@hotwired/stimulus";

import runCommon from "../common/common.ts";
import LoginFormController from "../stimulus/controllers/login_form_controller.ts";

declare global {
  interface Window {
    Stimulus: any;
  }
}

runCommon();

window.Stimulus = Application.start();

window.Stimulus.register("login-form", LoginFormController);
