import { Controller } from "@hotwired/stimulus";
import { LOCAL_STORAGE_KEYS } from "../../const.ts";
import { encodeBase64, decodeBase64, decodeUTF8 } from "tweetnacl-util";
import runKdf from "../../lib/kdf.ts";
import { Salt } from "../../shared_types/salt.gen.schema";

const enum FormState {
  WAIT = "wait",
  OK = "ok",
  ERROR = "error",
}

export default class LoginFormController extends Controller<HTMLDivElement> {
  static targets = [
    "form",
    "fieldEmail",
    "fieldIsConsenting",
    "fieldPassword",
    "fieldEncryptedSecretIpKeyNonce",
    "fieldEncryptedSecretIpKey",
    "fieldRawPassword",
    "fieldIsConsentingExt",
    "submitButton",
    "statusWait",
    "statusError",
    "statusOk",
    "statusPlaceholder",
  ];

  declare readonly formTarget: HTMLFormElement;
  declare readonly fieldEmailTarget: HTMLInputElement;
  declare readonly fieldPasswordTarget: HTMLInputElement;
  declare readonly fieldRawPasswordTarget: HTMLInputElement;
  declare readonly submitButtonTarget: HTMLButtonElement;
  declare readonly statusWaitTarget: HTMLParagraphElement;
  declare readonly statusErrorTarget: HTMLParagraphElement;
  declare readonly statusOkTarget: HTMLParagraphElement;
  declare readonly statusPlaceholderTarget: HTMLParagraphElement;

  static values = {
    saltRetrievalUrl: String,
  };

  // it doesn't *have* to be readonly, it just is in this case
  declare readonly saltRetrievalUrlValue: string;

  // noinspection JSUnusedGlobalSymbols
  static classes = ["hidden", "buttonDisabled"];

  // tailwind for display: none
  declare readonly hiddenClass: string;
  declare readonly buttonDisabledClasses: string[];

  // resubmission guard just in case
  isAttempted: boolean = false;

  setFormState(newState: FormState) {
    this.statusPlaceholderTarget.classList.add(this.hiddenClass);
    this.submitButtonTarget.classList.add(...this.buttonDisabledClasses);
    const reset = () => {
      this.statusWaitTarget.classList.add(this.hiddenClass);
      this.statusErrorTarget.classList.add(this.hiddenClass);
      this.statusOkTarget.classList.add(this.hiddenClass);
    };

    if (newState === FormState.WAIT) {
      reset();
      this.statusWaitTarget.classList.remove(this.hiddenClass);
    } else if (newState === FormState.ERROR) {
      reset();
      this.statusErrorTarget.classList.remove(this.hiddenClass);
    } else if (newState === FormState.OK) {
      reset();
      this.statusOkTarget.classList.remove(this.hiddenClass);
    }
  }

  checkForm(): boolean {
    const visibleInputs = [this.fieldEmailTarget, this.fieldRawPasswordTarget];
    return visibleInputs.every((el) => el.reportValidity());
  }

  // noinspection JSUnusedGlobalSymbols
  submitForm() {
    if (!this.checkForm()) {
      return;
    }

    if (this.isAttempted) {
      // this should not happen, but just in case it does ignore further attemts
      // to submit without page refresh
      return;
    }

    this.isAttempted = true;
    this.setFormState(FormState.WAIT);

    const rawPasswordBytes = decodeUTF8(this.fieldRawPasswordTarget.value);

    // this should help prevent password submission to the server
    // (in addition to missing "name" on the field itself)
    this.fieldRawPasswordTarget.value = "";
    this.fieldRawPasswordTarget.disabled = true;

    const queryParams = new URLSearchParams({ email: this.fieldEmailTarget.value });
    fetch(`${this.saltRetrievalUrlValue}?${queryParams}`)
      .then((response) => {
        if (!response.ok) {
          this.setFormState(FormState.ERROR);
          throw new Error(
            `Unexpected HTTP error, status ${response.status}, body ${response.body}`
          );
        }

        return response.json() as Promise<Awaited<Salt>>;
      })
      .then((salt) => {
        return runKdf(
          rawPasswordBytes,
          decodeBase64(salt.kdfSaltPwdB64),
          decodeBase64(salt.kdfSaltIpKeyB64)
        );
      })
      .then(([kdfPasswordBytes, ipKeyKey, _]) => {
        // I don't have the encrypted key yet, store the key in the local storage and
        // try to log in, if login is successful the browser is redirected to a page
        // where the encrypted key will be present
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.secretIpKeySymmetricKey,
          encodeBase64(ipKeyKey)
        );

        this.fieldPasswordTarget.value = encodeBase64(kdfPasswordBytes);

        this.setFormState(FormState.OK);

        this.formTarget.submit();
      });
  }
}
